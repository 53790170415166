<template>
<div>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          color="green"
          title="Товары"
          text="Список всех товаров"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>
                <v-btn color="primary" small flat icon @click="pickToEditSku(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn color="error" small flat icon @click="pickToDeleteSku(item)" >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </td>
              <td>
                <v-img 
                  :src="imageUrl(item.image)"
                  :alt="item.name"
                  xs12>
                </v-img>
              </td>
              <td :class="{'text-danger': item.name.length > 25}">{{ item.name }}</td>
              <td>{{ item.desc }}</td>
              <td>{{ getCategoryName(item.categoryId) }}</td>
              <td class="text-xs-right">{{ item.price }}</td>
              <td class="text-xs-right">{{ item.otc }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
  <v-container fluid>
    <v-layout row wrap>
        <v-btn color="#66bb6a" @click="addSkuDialog = true; this.isSaving = false">Добавить товар</v-btn>
        <v-btn color="primary" :to="{ name: 'Категории'}">Управление категориями</v-btn>
        <v-btn v-if="$auth.ready() && $auth.check(['admin'])" color="primary" @click="syncAll">Синхронизировать</v-btn>
        <v-btn v-if="$auth.ready() && $auth.check(['admin'])" color="primary" @click="syncVendAnal">Венд Аналитика</v-btn>
        
      
    </v-layout>
  </v-container>
  <v-dialog v-model="addSkuDialog" max-width="600">
    
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Добавление товар</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="addSkuDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
                        
                        
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Название</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="[rules.required, rules.maxLength40]"
                                    label="Название" 
                                    v-model="newSKU.name"
                                    maxlength="40"
                                    counter
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Краткое название</v-flex>
                <v-flex xs8>
                    <v-text-field :rules="[rules.required, rules.maxLength]"
                                  label="Краткое название" 
                                  v-model="newSKU.shortName" 
                                  maxlength="25"
                                  counter
                                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Описание</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="[rules.required]"
                                  label="Описание" 
                                  v-model="newSKU.desc"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Цена</v-flex>
                <v-flex xs8>
                    <v-text-field :rules="[rules.required]"
                                  label="Цена" 
                                  v-model="newSKU.price" 
                                  type="number"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">OTC</v-flex>
                <v-flex xs8>
                    <v-text-field :rules="[rules.required]"
                                  label="Комиссия" 
                                  v-model="newSKU.otc" 
                                  type="number"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Картинка</v-flex>
                <v-flex xs8>
                  <v-btn small color="primary" @click='pickFile' v-model='imageName' prepend-icon='attach_file'>Загрузить изображение</v-btn>
                  <v-img :src="imageUrl(newSKU.image)"
                                       max-height="100px"
                                       v-if="newSKU.image"
                                       contain></v-img>
                </v-flex>
              </v-layout>
              <v-layout wrap>
              <v-flex xs4 class="font-weight-bold">Показать сахар</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="newSKU.showSugar"
                    label="Показать панель сахара"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Акционный товар</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="newSKU.isPromo"
                    label="Участвует в акции"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
               <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Подарок</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="newSKU.isGift"
                    label="Можно выбрать в подарок"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Не идет в мэппинг</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="newSKU.excludedFromMapping"
                    label="Исключает товар из любого мэппинга для автоматов"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Серийный номер в венданалитике" 
                                  v-model="newSKU.vendAnaliticaId"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Категория</v-flex>
                  <v-flex xs8>
                      <v-autocomplete
                        label="Выберите категорию"
                        v-model="newSKU.categoryId"
                        :items="categoryItems"
                        item-text="name"
                        item-value="id"
                        :rules="[rules.required]"
                      >
                      </v-autocomplete>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" @click="addSku" class="white--text" :disabled="!valid || isSaving">Сохранить</v-btn>
                  <v-btn color="red darken-1" @click.native="addSkuDialog = false" class="white--text">Отмена</v-btn>
              </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
  </v-dialog>

  <v-dialog v-model="editSkuDialog" max-width="600">
    
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Редактирование товара</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="editSkuDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
                        
                        
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Название</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="[rules.required, rules.maxLength40]"
                                    label="Название" 
                                    v-model="editSKU.name" 
                                    maxlength="40"
                                    counter
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Краткое название</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="[rules.required, rules.maxLength]"
                                    label="Название" 
                                    v-model="editSKU.shortName" 
                                    maxlength="25"
                                    counter
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Описание</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="[rules.required]"
                                  label="Описание" 
                                  v-model="editSKU.desc"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Цена</v-flex>
                <v-flex xs8>
                    <v-text-field :rules="[rules.required]"
                                  label="Цена" 
                                  v-model="editSKU.price" 
                                  type="number"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Картинка</v-flex>
                <v-flex xs8>
                  <v-btn small color="primary" @click='pickFile' v-model='imageName' prepend-icon='attach_file'>Загрузить изображение</v-btn>
                  <v-img :src="imageUrl(editSKU.image)"
                                       max-height="100px"
                                       v-if="editSKU.image"
                                       contain></v-img>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Показать сахар</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="editSKU.showSugar"
                    label="Показать панель сахара"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Акционный товар</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="editSKU.isPromo"
                    label="Участвует в акции"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
               <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Подарок</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="editSKU.isGift"
                    label="Можно выбрать в подарок"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Не идет в мэппинг</v-flex>
                <v-flex xs8>
                  <v-checkbox
                    v-model="editSKU.excludedFromMapping"
                    label="Исключает товар из любого мэппинга для автоматов"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Серийный номер в венданалитике" 
                                  v-model="editSKU.vendAnaliticaId"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Категория</v-flex>
                  <v-flex xs8>
                      <!-- <v-select label="Категория"
                                :items="categoryItems"
                                item-text="name"
                                item-value="id"
                                :rules="rules.required"
                                v-model="editSKU.categoryId"></v-select> -->
                      <v-autocomplete
                        label="Выберите категорию"
                        v-model="editSKU.categoryId"
                        :items="categoryItems"
                        item-text="name"
                        item-value="id"
                        :rules="[rules.required]"
                      >
                      </v-autocomplete>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" @click="updateSku" class="white--text" :disabled="!valid || isSaving">Сохранить</v-btn>
                  <v-btn color="red darken-1" @click.native="editSkuDialog = false" class="white--text">Отмена</v-btn>
              </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
  </v-dialog>

  <v-dialog v-model="deleteSkuDialog" max-width="600">
    
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Удаление товара</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="deleteSkuDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-layout wrap>
            Вы действительно хотите удалить {{ editSKU.name }}?
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="deleteSku" class="white--text" :disabled="!valid || isSaving">Удалить</v-btn>
            <v-btn color="red darken-1" @click.native="deleteSkuDialog = false" class="white--text">Отмена</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
  </v-dialog>
  <v-alert
      v-model="alert"
      dismissible
      type="success"
    >
      SKU синхронизированы
    </v-alert>
    <input type="file"
              ref="file"
              accept="image/*"
              @change="fileChanged()"
              style="display: none"
              value="Выберите файл" />
  </div>
</template>

<script>
// import { getImageURL } from "@/utils/images"

export default {
  data: () => ({
    imageName: null,
    valid: false,
    addSkuDialog: false,
    editSkuDialog: false,
    deleteSkuDialog: false,
    alert: false,
    isSaving: false,
    headers: [
      {
        sortable: false,
        text: '',
        width: '120px'
      },
      {
        sortable: false,
        text: '',
        width: '120px'
      },
      {
        sortable: true,
        text: 'Название',
        value: 'name'
      },
      {
        sortable: false,
        text: 'Описание',
        value: 'desc'
      },
      
      {
        sortable: true,
        text: 'Категория',
        value: 'categoryId',
        
      },
      {
        sortable: true,
        text: 'Цена',
        value: 'price',
        align: 'right'
      },
      {
        sortable: true,
        text: 'OTC',
        value: 'otc',
        align: 'right'
      }
    ],
    items: [

    ],
    categoryItems: [
      { id: 1, name: 'Напитки'},
      { id: 2, name: 'Молочные продукты'},
    ],
    rules: {
        required: v => !!v || 'Поле обязательно',
        maxLength: v=> v.length <= 25 || 'Максимум 25 символов',
        maxLength40: v=> v.length <= 40 || 'Максимум 40 символов'
    },
    newSKU: {
      price: 0,
      name: null,
      shortName: null,
      desc: null,
      image: null,
      categoryId: 1,
      sku: 'default',
      otc: 0,
      showSugar: true,
      isPromo: false,
      isGift: false,
      excludedFromMapping: false,
      vendAnaliticaId: null
    },
    editSKU: {
      
    }
  }),
  computed: {
    categoryName: function(id){
      return this.categoryItems.find(x=>x.id == id).name
    },
    
  },
  mounted () {
    this.getSKU()
    this.getCategories()
  },
  methods: {
    imageUrl: function (imgUrl) {
      return process.env.VUE_APP_IMAGE_URL + imgUrl
    },
    getCategoryName(id){
      return this.categoryItems.find(x=>x.id == id).name
    },
    pickToEditSku(item){
      this.editSKU = item
      this.editSkuDialog = true
      this.isSaving = false
    },
    pickToDeleteSku(item){
      this.editSKU = item
      this.deleteSkuDialog = true
      this.isSaving = false
    },
    getSKU (){
      this.$http.get('/shopitems')
        .then(response => {
          this.items = response.data
        })
    },
    getCategories(){
      this.$http.get('/ShopItemCategory')
        .then(response => {
          this.categoryItems = response.data
        })
    },
    addSku(){
      this.isSaving = true
      this.$http.post('/shopitems', this.newSKU).
          then(response => {
            this.addSkuDialog = false
            this.items.push(response.data)
            this.isSaving = false
          }).
          catch(e => {
              console.log(e)
              this.isSaving = false
          });
      
    },
    updateSku(){
      this.isSaving = true
      this.$http.put('/shopitems/' + this.editSKU.id, this.editSKU).
          then(response => {
            this.editSkuDialog = false
            this.editSKU = {};
            this.isSaving = false
          }).
          catch(e => {
              console.log(e)
              this.isSaving = false
          });

      
    },
    deleteSku(){
      this.isSaving = true
      this.$http.delete('/shopitems/' + this.editSKU.id).
        then(response => {
              this.deleteSkuDialog = false
              this.isSaving = false
              this.editSKU = {};
              this.getSKU();
            }).
            catch(e => {
              this.isSaving = false
                console.log(e)
            });
    },
    syncAll(){
      this.$http.get('/shopitems/update').
          then(response => {
            console.log(response)
            this.alert = true
          }).
          catch(e => {
              console.log(e)
          });
    },
    syncVendAnal(){
      this.$http.get('/VendAnalitics/sync').
          then(response => {
            console.log(response)
            this.alert = true
          }).
          catch(e => {
              console.log(e)
          });
    },
    pickFile(){
      this.$refs.file.click()
    },
    fileChanged () {
      let formData = new FormData();
      console.log(this.$refs.file.files[0])
      formData.append('file', this.$refs.file.files[0]);
      // this.loading = true;
      this.$http.post('/files/',
          formData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }).
          then(response => {
              // this.loading = false;
              console.log(response);
              this.newSKU.image = response.data;
              this.editSKU.image = response.data;
          }).
          catch(e => {
              // this.loading = false;
              console.log(e)
          })


        },
  }
}
</script>

<style>
  .sku-card{
    margin-top: 44px;
  }
  .sku-icon{
    margin-top: -40px;
    height: 100px;
    width: 100px;
  }
  .text--white{
    color: #fff !important;
  }
</style>
